import React from "react";
import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { createRoot } from "react-dom/client";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { router } from "./router";
import { queryClient } from "./queryClient";
// TODO: This CSS-file is huge and we don't need all of the styles, we should copy the styles we need or write our own
import "@adyen/adyen-web/dist/adyen.css";
import "./index.css";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: [
    /^https:\/\/api\.test\.matchi\.com/,
    /^https:\/\/api\.matchi\.com/,
  ],
  tracesSampleRate: 0.2,
});

// @see https://www.docs.developers.amplitude.com/data/sdks/browser-2/#configuration
amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
  logLevel:
    import.meta.env.MODE === "development"
      ? amplitude.Types.LogLevel.Debug
      : undefined,
  // @see https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-default-events
  defaultTracking: {
    pageViews: true,
    sessions: true,
    attribution: false,
    formInteractions: false,
    fileDownloads: false,
  },
});

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
